import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
  image5: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projekte/yonder/image1.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(relativePath: { eq: "projekte/yonder/image2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(relativePath: { eq: "projekte/yonder/image3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(relativePath: { eq: "projekte/yonder/image4.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image5: file(relativePath: { eq: "projekte/yonder/image5.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto p-32">
          <iframe
            title="1 Minute audiovisuelle Freude"
            className="h-256 lg:h-512"
            frameBorder="0"
            allow="fullscreen; display-capture; autoplay"
            src="https://player.vimeo.com/video/7400034?h=ea83d79071"
            width="100%"
          />
          <div className="flex-1 py-32 mb-16">
            <h3>Yonder - Emilia Forstreuter</h3>
            <p>
              In dem Film <b>Yonder</b> wird der Betrachter mit auf eine Reise
              durch eine Welt mit ihren eigenen Formen und Organismen genommen.
              So reist man durch Erde, Wasser und Luft und wird damit zu einem
              Betrachter eines Naturkreislaufes.
            </p>

            <div className="flex flex-col md:flex-row">
              <GatsbyImage
                className="my-32 w-full md:w-1/2 mr-16"
                objectFit="contain"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt=""
              />
              <GatsbyImage
                className="my-32 w-full md:w-1/2"
                objectFit="contain"
                image={data.image5.childImageSharp.gatsbyImageData}
                alt=""
              />
            </div>
            <p className="mb-16">
              Die Idee für den Film <b>Yonder</b> basiert auf dem Interesse für
              die Komplexität und Einfachheit der Welt, in der wir leben. Sehr
              vereinfacht ausgedrückt besteht unsere Welt aus simplen
              Grundmodulen - den Zellen - die dann so oft wiederholt und
              kombiniert werden bis komplexe Organismen und Strukturen
              entstehen.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 md:-mb-40 lg:-mb-92 self-end z-10 w-full md:w-1/2"
                objectFit="cover"
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Fensterprosa"
              />
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Fensterprosa"
              />
              <GatsbyImage
                className="my-8 md:my-0 md:-mt-40 lg:-mt-92 self-end w-full md:w-1/2"
                objectFit="cover"
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Fensterprosa"
              />
            </div>
            <p>
              Dieses Prinzip aus der Natur hat <b>Emilia Forstreuter</b> für den
              Film <b>Yonder</b> adoptiert. Durch die Wiederholung von simplen
              Grundmodulen sollte eine neue Welt mit ihren eigenen Formen und
              Organismen entstehen. In der Natur vorkommende Basisbewegungen
              sollen den Formen ein lebendiges Gefühl verleihen. Emilias Ziel
              war es eine andere Welt zu schaffen, die einerseits fremd und doch
              vertraut erscheint.
            </p>
            <div className="pt-32">
              <b>
                <a
                  href="http://www.emiliaforstreuter.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zur Webseite der Künstlerin
                </a>
              </b>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Yonder</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="In dem Film Yonder wird der Betrachter mit auf eine Reise
      durch eine Welt mit ihren eigenen Formen und Organismen genommen.
      So reist man durch Erde, Wasser und Luft und wird damit zu einem
      Betrachter eines Naturkreislaufes."
      publicURL={props.data.image1.publicURL}
      title="Yonder"
    />
  );
};

export default Page;
